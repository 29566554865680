import React, { useState } from "react"

import Head from "decentraland-gatsby/dist/components/Head/Head"
import NotFound from "decentraland-gatsby/dist/components/Layout/NotFound"
import useAsyncMemo from "decentraland-gatsby/dist/hooks/useAsyncMemo"
import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import { Button } from "decentraland-ui/dist/components/Button/Button"
import { Container } from "decentraland-ui/dist/components/Container/Container"

import Hero from "../../../components/Blog/CategoryHero/CategoryHero"
import BlogNavigation from "../../../components/Layout/BlogNavigation"
import Layout from "../../../components/Layout/Layout"
import PostList from "../../../components/Layout/PostList"
import {
  getBlogCategoryById,
  getBlogpostByCategory,
} from "../../../modules/contentful"
import {
  BlogPostAttributes,
  createBlogPostList,
} from "../../../modules/formats"

import "./index.css"

const POST_PER_PAGE = 6

export default function CategoryPage(props: any) {
  const l = useFormatMessage()
  const [blogPosts, setBlogPosts] = useState<BlogPostAttributes[]>([])
  const [hasLoadMore, setHasLoadMore] = useState(true)
  const [fetchOptions, setFetchOptions] = useState({
    offset: 0,
    limit: POST_PER_PAGE,
  })

  const [category, categoryState] = useAsyncMemo(
    async () => getBlogCategoryById(props.category),
    [props.category]
  )

  const [, blogpostsState] = useAsyncMemo(
    async () => {
      if (!category) {
        return false
      }
      const blogpostContent = await getBlogpostByCategory(
        category,
        fetchOptions
      )
      const blogpostCurated = await createBlogPostList(blogpostContent, [
        category,
      ])
      setBlogPosts((prev) => {
        let newBlogPost: BlogPostAttributes[] = []
        if (fetchOptions.offset !== 0) {
          newBlogPost = [...prev]
        }
        newBlogPost = [...newBlogPost, ...blogpostCurated]
        if (newBlogPost.length >= blogpostContent.total) {
          setHasLoadMore(false)
        }
        return newBlogPost
      })
    },
    [category, fetchOptions],
    {
      callWithTruthyDeps: true,
    }
  )

  const loading =
    !categoryState.loaded ||
    !blogpostsState.loaded ||
    categoryState.loading ||
    blogpostsState.loading

  return (
    <Layout
      {...props}
      isFullscreen
      isOverlay
      hideFooter={false}
      className="layout__blog"
    >
      <Head
        title={category?.fields.title || ""}
        description={category?.fields.description || ""}
      />
      <BlogNavigation active={props.category} />
      {categoryState.loaded && category && category.fields.image && (
        <Hero
          category={category.fields.title}
          description={category.fields.description}
          image={category.fields.image.fields.file.url}
        />
      )}
      <Container>
        {!loading && blogPosts && blogPosts.length === 0 && (
          <div className="blog-post__empty-container">
            <NotFound
              title={l("page.blog.nothing_to_show")}
              description={l("page.blog.categories.empty_list")}
            />
          </div>
        )}
        <PostList
          blogPosts={blogPosts}
          loading={!categoryState.loaded}
          className="category-post__wrapper"
        />
        {hasLoadMore && (
          <div className="blog-post__load-more">
            <Button
              onClick={() => {
                setFetchOptions((prev) => ({
                  limit: POST_PER_PAGE,
                  offset: prev.offset + POST_PER_PAGE,
                }))
                setTimeout(
                  () =>
                    window.scrollBy({ top: 500, left: 0, behavior: "smooth" }),
                  0
                )
              }}
            >
              {l("page.blog.load_more")}
            </Button>
          </div>
        )}
      </Container>
    </Layout>
  )
}
