import React from "react"

import Paragraph from "decentraland-gatsby/dist/components/Text/Paragraph"
import Title from "decentraland-gatsby/dist/components/Text/Title"

import "./CategoryHero.css"

export type CategoryHero = {
  category: string
  description: string
  image: string
}

export default React.memo(function CategoryHero(props: CategoryHero) {
  const { category, description, image } = props

  return (
    <div className="category-hero">
      <div
        className="category-hero__image"
        style={{ backgroundImage: `url(${image})` }}
      ></div>
      <div className="category-hero__wrapper">
        <Title className="category-hero__title">{category}</Title>
        <Paragraph className="category-hero__description">
          {description}
        </Paragraph>
      </div>
    </div>
  )
})
